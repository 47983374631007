import getObjectID from "../../../../../util/controllers/getObjectID";
import sendMessage from "./sendMessage";

export default function sendJoinedChatGroup(chatGroupID) {
  return new Promise((resolve) => {
    window.chatSocket.emit("joinedChatGroup", {
      chatGroupID,
    });

    let messageID = getObjectID();

    let messageDoc = { _id: messageID, type: "MEMBER_JOINED" };

    sendMessage({
      messageDoc,
      chatGroup: { _id: chatGroupID },
      onSuccess: resolve,
    });
  });
}
