import { serverLine } from "../../../../util/lib/serverLine";
import sendIsSeenMessage from "../socket/send/sendIsSeenMessage";

//Clean load means
//Current data will be erased and then new data will be loaded
export default function loadSelectedChatGroup(selectedChatGroupID) {
  let {
    setSelectedChatGroupData,
    setSelectedChatGroupIsLoading,
    setReplyingTo,
  } = window.globalChatSpace;

  // console.log("reloading..chat msg..");
  if (!selectedChatGroupID) {
    setSelectedChatGroupData(null);
    return null;
  }

  // console.log("reloading..chat msg..2");

  setReplyingTo(null);

  setSelectedChatGroupIsLoading(true);

  let url = "/selected-chat-group/?chatGroupID=" + selectedChatGroupID;

  serverLine
    .get(url)
    .then((selectedChatGroupData) => {
      let dataBelongToSelectedChatGroup = checkDataBelongToSelectedChatGroup(
        selectedChatGroupData
      );

      if (!dataBelongToSelectedChatGroup) {
        console.warn("data belongs to some other chat group");
        return setSelectedChatGroupIsLoading(false);
      }

      //If user clicks on too many chat groups really fast
      //They might get data of chat group they clicked previously

      if (selectedChatGroupData) {
        setSelectedChatGroupData(selectedChatGroupData);
        removeUnreadMessageBubble();
        sendIsSeenMessage(selectedChatGroupData.group._id);
      }

      setSelectedChatGroupIsLoading(false);
    })
    .catch((e) => {
      setSelectedChatGroupIsLoading(false);
      window.popupAlert(e.message);
      console.warn(e);
    });

  function removeUnreadMessageBubble() {
    const {
      selectedChatGroupID,
      chatMembershipAndNotifications,
      setChatMembershipAndNotifications,
    } = window.globalChatSpace;

    let newMembershipAndNotifications = { ...chatMembershipAndNotifications };

    let doc = newMembershipAndNotifications[selectedChatGroupID];

    if (doc) {
      doc.unreadMessages = 0;
      doc.unreadMentions = 0;
    }

    setChatMembershipAndNotifications(newMembershipAndNotifications);
  }

  function checkDataBelongToSelectedChatGroup(selectedChatGroupData) {
    let { selectedChatGroupID } = window.globalChatSpace;

    if (!selectedChatGroupData) {
      if (!selectedChatGroupID) {
        return true;
      } else {
        return false;
      }
    }

    let dataBelongsTo = selectedChatGroupData.group._id;

    return dataBelongsTo === selectedChatGroupID;
  }
}
