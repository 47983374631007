import { styled } from "styled-components";
import { useEffect } from "react";
import MessageBox from "./messageBox/MessageBox";
import LoadingSection from "../../../../util/components/LoadingSection";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: calc(100% - 300px); */
  overflow: hidden;
`;

const Messages = styled.div`
  display: flex;
  flex: 1;
  /* height: calc(100% - 300px); */
  /* height: calc(100% - 195px); // 200 subtracted for bottom bar */
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

export default function ChatMessagesPanel() {
  const { selectedChatGroupID, selectedChatGroupData } = window.globalChatSpace;

  useEffect(() => {
    console.log("Scrolling to the end");
    reachChatPanelEnd();
  }, []); //ChatGroupBox was removed because it was scroll to the bottom on reactions as well

  let { selectedChatGroupIsLoading } = window.globalChatSpace;

  if (selectedChatGroupIsLoading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  if (!selectedChatGroupData)
    return (
      <Container>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let group = selectedChatGroupData.group;

  let messages = selectedChatGroupData.messages;

  return (
    <Messages id="chat-panel">
      {messages.map((item) => (
        <MessageBox key={item._id} group={group} message={item} />
      ))}
    </Messages>
  );
}
