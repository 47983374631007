import { styled } from "styled-components";
import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import { GoImage } from "react-icons/go";
import sendTypingData from "../../controllers/socket/send/sendTypingData";
import sendMessage from "../../controllers/socket/send/sendMessage";
import handleSendingIfBlocked from "../../../../util/controllers/utils/handleSendingIfBlocked";
import LoadingSection from "../../../../util/components/LoadingSection";
import MaterialInput from "../../../../util/components/MaterialInput";
import compressAndUploadFile from "../../../../util/controllers/compressAndUploadFile";
import selectFile from "../../../../util/controllers/selectFile";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import ReplyingToInfo from "./ReplyingToInfo";
import getObjectID from "../../../../util/controllers/getObjectID";

const BottomPart = styled.div`
  /* height: 100px; */
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  padding: 15px 15px;
  /* height: 150px; */
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    padding: 20px 20px;
    border: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-direction: row;
  width: 100%;
`;

export default function ChatPanelBottomBar() {
  const { popupAlert, isMobile, globalChatSpace } = useContext(Context);
  const [messageText, setMessageText] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);
  const [typingDataSentAt, setTypingDataSentAt] = useState(null);

  const { selectedChatGroupData, replyingTo } = globalChatSpace;

  let { selectedChatGroupIsLoading } = window.globalChatSpace;

  useEffect(() => {
    const inputElement = document.getElementById("messageInput");

    if (inputElement) {
      inputElement.focus();
    }
  }, [replyingTo]);

  if (selectedChatGroupIsLoading || !selectedChatGroupData) return null;

  let loggedInUserIsBlocked = selectedChatGroupData.loggedInUserIsBlocked;
  let oppositeUserIsBlocked = selectedChatGroupData.oppositeUserIsBlocked;

  let bottomBtnStyle = { height: "56px", width: "56px" };

  let theBottomPart = (
    <>
      <MaterialInput
        id="messageInput"
        onEnter={postMessage}
        label={"Type Message Here"}
        onChange={updateMessage}
        value={messageText}
        maxRows={5}
        multiline={true}
      />

      <FilledButton
        isLoading={uploadingImage}
        style={bottomBtnStyle}
        onClick={uploadImage}
        icon={<GoImage />}
      />

      {isMobile ? null : (
        <FilledButton
          style={bottomBtnStyle}
          variant="filled"
          onClick={postMessage}
          icon={<AiOutlineSend />}
        />
      )}
    </>
  );

  return (
    <BottomPart>
      <ReplyingToInfo />
      <Row>{getBottomPart()}</Row>
    </BottomPart>
  );

  function getBottomPart() {
    if (loggedInUserIsBlocked) return "You have been blocked";
    if (oppositeUserIsBlocked) return "You have blocked the other person";
    return theBottomPart;
  }

  function updateMessage(e) {
    setMessageText(e.target.value);
    sendTypingData({
      typingDataSentAt,
      setTypingDataSentAt,
      selectedChatGroupData,
    });
  }

  async function uploadImage() {
    let messageID = getObjectID();

    let images = await selectFile({ onlyImage: true });

    if (!images) return null;

    if (!window.localImages) {
      window.localImages = {};
    }

    let theImage = images[0];

    setUploadingImage(true);

    window.localImages[messageID] = theImage;

    if (theImage) {
      try {
        let fileData = await compressAndUploadFile({
          selectedFile: theImage,
          folder: "chat",
        });
        let theImageData = { type: "S3_UPLOAD", data: fileData.fileName };

        let messageDoc = {
          _id: messageID,
          message: { image: theImageData },
          type: "GENERAL",
        };

        sendMessage({
          messageDoc: messageDoc,
          chatGroup: selectedChatGroupData.group,
        });

        setUploadingImage(false);
      } catch (e) {
        console.warn(e);
        setUploadingImage(false);
        window.popupAlert(e.message);
      }
    }
  }

  function postMessage() {
    if (!messageText) return window.doAlert("Message can't be empty");
    if (!messageText.trim()) return window.doAlert("Message can't be empty");

    if (handleSendingIfBlocked({ data: selectedChatGroupData, popupAlert })) {
      return;
    }

    let messageID = getObjectID();
    // console.log("messageID", messageID, ObjectID().toString());
    // window.popupAlert("aaa");
    // window.popupAlert(messageID);

    let messageDoc = {
      _id: messageID,
      message: { text: messageText },
      type: "GENERAL",
    };

    sendMessage({
      messageDoc,
      chatGroup: selectedChatGroupData.group,
    });

    setMessageText("");
  }
}
