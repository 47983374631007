import { useContext, useEffect } from "react";
import Context from "../../../../Context";
import styled from "styled-components";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import { AiOutlineClose, AiOutlineNotification } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { RiNotification2Line } from "react-icons/ri";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import BrandContainer from "../../../../util/components/brand/BrandContainer";
import { serverLine } from "../../../../util/lib/serverLine";
import requestPushNotificationPermission from "../requestPushNotifPermission";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  background-color: var(--primarySurface);
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 900;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 16px;
  }
`;
const Subtitle = styled.div`
  font-size: 13px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 12px;
  }
`;

const Title2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 19px;
  font-weight: 900;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;
const Subtitle2 = styled.div`
  font-size: 13px;
  text-align: center;
`;
const Buttons = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;

  @media (max-width: 900px) {
    /* width: 100%; */
    /* justify-content: space-between; */
    /* flex-direction: row-reverse; */
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width: 90%; */
  padding-right: 50px;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

const Text2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  /* width: 90%; */
`;

const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  height: 50px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.5);
    /* background-color: var(--secondaryInput); */
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const NotificationIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 19px;
  align-items: center;
  /* display: none; */

  @media (max-width: 900px) {
    font-size: 30px;
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  background-color: var(--primarySurface);
  border-radius: 10px;
  padding: 0;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export default function EnablePushNotificationCard({ hideCloseButton }) {
  const { pushNotificationDecision, loggedInUser } = useContext(Context);
  const { showPopup } = usePopupDialog();

  let popupComponent = (
    <Container>
      <TheCard hideCloseButton={true} />
    </Container>
  );

  useEffect(() => {
    if (loggedInUser) {
      if (!pushNotificationDecision) {
        console.log("doing popup...");
        window.setTimeout(() => {
          showPopup({
            title: "Enable Notifications",
            component: popupComponent,
            hideHeader: true,
          });
        }, 1000);
      }
    }
  }, [loggedInUser, pushNotificationDecision]);

  if (
    pushNotificationDecision == "GRANTED" ||
    pushNotificationDecision == "CANCELLED"
  )
    return null;

  return (
    <Card>
      <TheCard />
    </Card>
  );
}

function TheCard({ hideCloseButton }) {
  const { updatePushNotificationDecision } = useContext(Context);
  const { closePopup } = usePopupDialog();
  return (
    <>
      <>
        <Text>
          <TitleRow>
            <NotificationIcon>
              <RiNotification2Line />
            </NotificationIcon>

            <Title>Cotton Chat uses push notifications</Title>
          </TitleRow>

          <Subtitle>
            It will to help you stay on top of all your discussions
          </Subtitle>
        </Text>

        <Buttons>
          {hideCloseButton ? null : (
            <FilledButton
              //  colorVariation={"SURFACE"}
              icon={<AiOutlineClose />}
              onClick={() => {
                updatePushNotificationDecision("CANCELLED");
              }}
            ></FilledButton>
          )}

          <FilledButton
            style={{ width: "100px" }}
            onClick={() => {
              closePopup();
              requestPushNotificationPermission();
            }}
          >
            Okay
          </FilledButton>
        </Buttons>
      </>
    </>
  );
}
