import { useEffect, useState } from "react";

import LoadingSection from "../../../../util/components/LoadingSection";
import styled from "styled-components";
import ChatMemberBox from "./ChatMemberBox";
import { serverLine } from "../../../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

export default function ChatMembersSection() {
  const [data, setData] = useState(false);

  useEffect(() => {
    loadMembers();
  }, []);

  if (!data)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  let list = data.members.map((item) => (
    <ChatMemberBox
      userData={item}
      voteData={data.voteOuts[item._id]}
      membershipData={data.memberships[item._id]}
    />
  ));

  return <Container>{list}</Container>;

  async function loadMembers() {
    let { selectedChatGroupID } = window.globalChatSpace;
    let data = await serverLine.get(
      `/chat-group-members/?chatGroupID=${selectedChatGroupID}`
    );
    setData(data);
  }
}
