import goTo from "../../../../util/controllers/goTo";
import sendJoinedChatGroup from "../socket/send/sendJoinedChatGroup";
import getChatGroup from "./getChatGroup";

export default async function openChat({ chatGroupType, contentID, userID }) {
  let data = await getChatGroup({ chatGroupType, contentID, userID });

  let { chatGroup, newJoin } = data;

  let chatGroupID = chatGroup._id;

  console.log("chatGroupID", chatGroupID);

  if (newJoin) await sendJoinedChatGroup(chatGroupID);

  goTo("/chat/" + chatGroupID + "/?popup=chat")();
}
