import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";

import { MdDone } from "react-icons/md";

import styled from "styled-components";
import { Slider } from "@mui/material";
import CustomButton from "./inputs/CustomButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  align-items: center;
`;

const CropContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 400px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 50px;
  width: 100%;
`;

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--translucentHard);
  border-radius: 10px;
  /* border: 1px solid var(--foreground); */
`;

const SliderDiv = styled.div`
  padding: 22px 0px;
  margin-left: 16;
`;

const createObjectURL = (blob) => {
  if (blob) {
    return URL.createObjectURL(blob);
  }
  return "";
};

const ImageCropper2 = ({ imageBlob, onCropDone }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [objectURL, setObjectURL] = useState(null);

  useEffect(() => {
    setObjectURL(createObjectURL(imageBlob));
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = imageBlob.name;
        resolve(blob);
      }, "image/png");
    });
  };

  const handleDone = async () => {
    // console.log("done....1");
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(imageBlob),
        croppedAreaPixels
      );
      // console.log("done....2");
      onCropDone(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <CropContainer>
        <Cropper
          image={objectURL}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </CropContainer>
      <Controls>
        <SliderContainer>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </SliderContainer>
        <CustomButton
          variant="filled"
          style={{ width: "100px" }}
          onClick={handleDone}
          icon={<MdDone />}
        >
          Done
        </CustomButton>
      </Controls>
    </Container>
  );
};

export default ImageCropper2;
