import goToAuthScreen from "../../../services/googleAuth/goToAuthScreen.js";
import { GrGoogle } from "react-icons/gr";
import { useState } from "react";
import FilledButton from "../../../util/components/buttons/FilledButton.js";

const btnStyle = {
  padding: "15px 30px",
  background: "transparent",
  color: "var(--accentColor)",
  border: "1px solid var(--accentColor)",
  boxShadow: "0px 3px 0px 1px var(--accentColor)",
};

export default function LoginWithGoogleBtn() {
  const [loading, setLoading] = useState(null);
  return (
    <FilledButton
      isLoading={loading}
      textStyle={{ fontWeight: "700" }}
      style={btnStyle}
      icon={<GrGoogle />}
      onClick={goToAuthScreen({ setLoading })}
    >
      Login With Google
    </FilledButton>
  );
}
