import goTo from "./goTo.js";
import universalLocalStorage from "../lib/universalLocalStorage.js";

async function logout() {
  await universalLocalStorage.removeItem("cottonchat-token");
  await universalLocalStorage.removeItem("cottonchat-userID");
  window.updateColorMode("LIGHT");
  goTo("/")();

  let { updatePushNotificationDecision } = window.sharedState;

  if (window.chatSocket) {
    window.chatSocket.disconnect();
  }

  window.doInitialLoad();

  updatePushNotificationDecision(null);
  // window.location = window.location.origin;

  // serverLine.delete("/cookie").then(() => {
  // window.location = window.location.origin;
  // });
}

export default logout;
