import { styled } from "styled-components";
import OnlineBubble from "../common/OnlineBubble";
import getChatUiData from "../../controllers/ui/getChatUiData";
import getUnreadMessageCount from "../../controllers/ui/getUnreadMessageCount";
import limitStringLength from "../../../../util/controllers/limitStringLength";
import getOnlineOfflineStatus from "../../../../util/controllers/socket/getOnlineOfflineStatus";
import getDateString from "../../../../util/controllers/getDateString";
import getImageURL from "../../../../util/controllers/getImageURL";
import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";
import { useContext } from "react";
import Context from "../../../../Context";
import { GoMention } from "react-icons/go";
import getUnreadMentionCount from "../../controllers/ui/getUnreadMentionCount";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  background: var(--secondarySurface);
  border-radius: 10px;
  animation: centerScaleReveal 0.25s ease-out forwards;
  /* box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.1); */

  ${({ $highlight }) => {
    if ($highlight)
      return `
        background: var(--primarySurface);
    `;

    return `

    &:hover{
     background: var(--primarySurface);
    }
       
    `;
  }}
`;
const Image = styled.img`
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 500px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 21px;
  gap: 7px;
  /* width: calc(100% - 80px); */
  overflow: hidden;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 13px;
  text-transform: capitalize;
  white-space: nowrap;
  /* width: 80%; */
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Subtext = styled.div`
  font-size: 13px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  gap: 10px;
`;
const UnreadMessages = styled.div`
  background-color: var(--accentColor);
  padding: 5px 8px;
  border-radius: 20px;
  color: var(--primarySurface);
  font-size: 11px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SecondLineInfo = styled.div``;

export default function ChatGroupBox({
  item,
  onClick,
  friendsLastSeenAt,
  highlight,
}) {
  let { oppositeMembers } = window.globalChatSpace;
  const { loggedInUserID } = useContext(Context);
  item = { ...item };

  let oppositeMemberID = getOppositeMemberID({
    loggedInUserID,
    chatGroup: item,
  });

  let onlineStatus = getOnlineOfflineStatus({
    friendsLastSeenAt,
    oppositeMemberID: oppositeMemberID,
  });

  let { title, image } = getChatUiData(item, oppositeMembers[oppositeMemberID]);

  let imageComp = (
    <ImageContainer key="imageContainer">
      <Image src={getImageURL(image, true)} />
      {onlineStatus ? <OnlineBubble /> : null}
    </ImageContainer>
  );

  if (item.type == "CONTENT_CHAT") {
    imageComp = null;
  }

  return (
    <Container
      $highlight={highlight}
      onClick={() => {
        onClick(item);
      }}
    >
      {imageComp}

      <Text>
        <Name>{limitStringLength(title, 25)} </Name>
        <Subtext>{getSecondLine()}</Subtext>
      </Text>
    </Container>
  );

  function getSecondLine() {
    let theSecondLine = [];

    theSecondLine.push(getUnreadMessages());
    theSecondLine.push(getUnreadMentions());

    if (item.oppositeMember) theSecondLine.push(getUsername());
    theSecondLine.push(getLastActivityDate());

    return theSecondLine;
  }

  function getUsername() {
    return (
      <SecondLineInfo key="usernameInfo">
        {"@" + item.oppositeMember.username}
      </SecondLineInfo>
    );
  }

  function getLastActivityDate() {
    return (
      <SecondLineInfo key="activityDate">
        {getDateString(item.lastMessageSentAt)}
      </SecondLineInfo>
    );
  }

  function getUnreadMessages() {
    let val = getUnreadMessageCount(item);
    if (!val) return null;
    return <UnreadMessages key="unreadMessages">{val}</UnreadMessages>;
  }

  function getUnreadMentions() {
    let val = getUnreadMentionCount(item);
    if (!val) return null;
    return (
      <UnreadMessages key="unreadMessagesMentions">
        <GoMention /> {val}
      </UnreadMessages>
    );
  }
}
