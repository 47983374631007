import { styled } from "styled-components";

import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
const Container = styled.div`
  font-size: 18px;
  opacity: 0.5;
`;

export default function TickStatus({ message }) {
  let { selectedChatGroupData, loggedInUserID } = window.globalChatSpace;

  let theSymbol = null;

  let seen = hasSeen();

  if (message.isNew) {
    theSymbol = <AiOutlineClockCircle />;

    if (message.isSent) {
      theSymbol = <BiCheck />;
    }

    if (seen) {
      theSymbol = <BiCheckDouble />;
    }
  } else {
    theSymbol = <BiCheck />;

    if (seen) {
      theSymbol = <BiCheckDouble />;
    }
  }

  return <Container>{theSymbol}</Container>;

  function hasSeen() {
    let createdAt = new Date(message.createdAt).getTime();
    let members = selectedChatGroupData.membersMembershipData;

    let seenCount = 0;

    for (let item of members) {
      if (item.userID == loggedInUserID) {
        seenCount++;
        continue;
      }

      let oppositeMemberLastSeenAt = new Date(item.lastSeenAt);

      if (oppositeMemberLastSeenAt.getTime() > createdAt) {
        seenCount++;
      } else {
        // console.log("Diff", oppositeMemberLastSeenAt.getTime() - createdAt);
      }
    }

    return seenCount >= members.length;
  }
}
