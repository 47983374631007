import { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import LoadingSection from "../../util/components/LoadingSection";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import UserBox from "../profilePage/UserBox";
import BlockUnblockInterface from "../profilePage/BlockUnblockInterface";
import Context from "../../Context";
import usePopupDialog from "../../util/lib/popupDialog/usePopupDialog";
import { serverLine } from "../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  gap: 20px;
`;

const Note = styled.small`
  opacity: 0.6;
`;

export default function Blocklist() {
  const { loggedInUserID } = useContext(Context);
  const { closePopup, showPopup } = usePopupDialog();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadList();
  }, []);

  if (loading) return <LoadingSection />;

  let list = data.map((item) => getItem(item));

  if (!list.length) list = <Note>You have not blocked anyone.</Note>;

  return (
    <Container>
      <Main>
        <BeautifulTitle>People blocked</BeautifulTitle>
        <List>{list}</List>
      </Main>
    </Container>
  );

  function loadList() {
    setLoading(true);
    serverLine.get("/blocklist").then((newData) => {
      setData(newData);
      setLoading(false);
    });
  }

  function getItem(item) {
    if (item.receiverUser) {
      return (
        <UserBox
          onClick={manageBlocking({
            userData: item.receiverUser,
            userID: item.receiverUserID,
          })}
          item={item.receiverUser}
        />
      );
    }
  }

  function manageBlocking({ userID, userData }) {
    return () => {
      showPopup({
        title: "Manage Blocking",
        component: (
          <BlockUnblockInterface
            receiverUserData={userData}
            receiverUserID={userID}
            senderUserID={loggedInUserID}
            callback={loadList}
          />
        ),
      });
    };
  }
}
