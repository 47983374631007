import styled from "styled-components";
import Options from "../../../../util/components/inputs/Options";
import sendReaction from "../../controllers/socket/send/sendReaction";
import { useContext } from "react";
import Context from "../../../../Context";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import FilledButton from "../../../../util/components/buttons/FilledButton";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  padding: 10px 18px;
  border-radius: var(--borderRadius);
  gap: 10px;
  font-size: 35px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  text-decoration: none;
  background-color: var(--secondaryInput);
  color: var(--primaryText);

  &:hover {
    transform: scale(0.95);
    background: var(--primaryInput);
  }

  ${({ $highlight }) => {
    if ($highlight)
      return `
          background: var(--primaryInput);
    `;
  }}

  @media (max-width: 900px) {
    flex: 1;
  }
`;

const None = styled.div`
  font-size: 21px;
`;

export default function AddMessageReaction({ item }) {
  const { closePopup } = usePopupDialog();
  let { loggedInUserID, selectedChatGroupData } = window.globalChatSpace;

  let options = [
    {
      label: "👍",
      value: "👍",
    },
    {
      label: "👎",
      value: "👎",
    },
    {
      label: "❤️",
      value: "❤️",
    },
    {
      label: "😂",
      value: "😂",
    },
    {
      label: "🫢",
      value: "🫢",
    },
    {
      label: "😥",
      value: "😥",
    },
    {
      label: "🤛",
      value: "🤛",
    },
    {
      label: "💯",
      value: "💯",
    },
    {
      label: "🔥",
      value: "🔥",
    },
    {
      label: "🌼",
      value: "🌼",
    },
    {
      label: "🌸",
      value: "🌸",
    },
    {
      label: "🌺",
      value: "🌺",
    },
    {
      label: "🌷",
      value: "🌷",
    },
    {
      label: <None>None</None>,
      value: null,
    },
  ];

  let theValue = null;

  if (item.reactions) {
    if (item.reactions[loggedInUserID])
      theValue = item.reactions[loggedInUserID].value;
  }

  return (
    <Container>
      {options.map((item) => (
        <Button
          $highlight={theValue == item.value}
          onClick={() => {
            onChange(item.value);
          }}
        >
          {item.label}
        </Button>
      ))}
    </Container>
  );

  function onChange(newValue) {
    sendReaction({
      messageID: item._id,
      reaction: newValue,
      chatGroup: selectedChatGroupData.group,
    });
    closePopup();
  }
}
