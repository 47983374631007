import styled from "styled-components";

const InfoBox = styled.div`
  margin-top: 100px;
  padding: 15px 50px;
  background: var(--secondarySurface);
  border: 1px solid var(--foreground);
  border-radius: 100px;
  max-width: 35vw;
  font-size: 15px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default InfoBox;
