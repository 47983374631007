import { getToken } from "firebase/messaging";
import uploadPushNotificationToken from "./uploadPushNotificationToken";

let VAPID =
  "BOa6RV9-IdAQMvSEq79p4JtPPBv_6bssSnwog1DxlJ19izpsV7AXtRDqhVMX06uCBx9rGHmMNh8_HcuCurNsdbs";

export default async function requestPushNotifPermissionWeb() {
  let { updatePushNotificationDecision } = window.sharedState;

  if (!window.firebaseSpace) {
    //because of navigator.onLine error firebase was not initialized
    return window.popupAlert(
      "This action cannot be completed. Restart the application to fix it"
    );
  }

  let { messaging } = window.firebaseSpace;
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    window.popupAlert("Push notifications enabled");
    updatePushNotificationDecision("GRANTED");
    const token = await getToken(messaging, {
      vapidKey: VAPID,
    });

    uploadPushNotificationToken({ tokenType: "WEB", token });
    //We can send token to server
    console.log("Token generated : ", token);
  } else if (permission === "denied") {
    //notifications are blocked
    window.popupAlert(
      "Permission has been denied. Please! enable it from outside settings."
    );
    updatePushNotificationDecision("DENIED");
  }
  //
  //
}
