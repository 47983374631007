import styled from "styled-components";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* min
  white-space: nowrap; */

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Hr = styled.div`
  height: 1px;
  flex: 1;
  opacity: 0.4;
  background: var(--accentColor);
`;

export default function HrWithTitle({ title }) {
  if (!title) return null;

  return (
    <Header>
      <HeaderTitle>{title}</HeaderTitle>
      <Hr />
    </Header>
  );
}
