import { useContext } from "react";
import styled from "styled-components";
import Context from "../../../../Context";
import MobileChatMainPanel from "./MobileChatMainPanel";
import DesktopChatMainPanel from "./DesktopChatMainPanel";
import InfoBox from "../../../../util/components/InfoBox";

export default function ChatMainPanel() {
  const { isMobile } = useContext(Context);

  let { selectedChatGroupID } = window.globalChatSpace;

  if (!selectedChatGroupID) return null;

  if (isMobile) return <MobileChatMainPanel />;

  return <DesktopChatMainPanel />;
}
