import styled from "styled-components";
import ChatMessagesPanel from "../chatMessagesPanel/ChatMessagesPanel";
import ChatMessagesPanelHeader from "../chatMessagesPanel/ChatMessagesPanelHeader";
import CustomToggle from "../../../../util/components/inputs/CustomToggle";
import { useState } from "react";
import ChatMembersSection from "../chatMembersSection/ChatMembersSection";
import ConclusionSection from "../conclusionSection/ConclusionSection";
import LoadingSection from "../../../../util/components/LoadingSection";
import ChatOptionsSection from "../chatOptionsSection/ChatOptionsSection";
import ChatPanelBottomBar from "../chatMessagesPanel/ChatPanelBottomBar";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  /* height: calc(100vh - var(--mainPadding) - var(--mainPadding)); */
  height: 100%;
  overflow: hidden;
`;

const ChatMessagesContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex: 1;
  flex-direction: column;

  background: var(--primarySurface);
  border-radius: 10px;
  animation: fadeIn 0.1s ease-out forwards;

  @media (max-width: 900px) {
    border: none;
    display: flex;
    ${({ selectedChatGroupID }) => {
      if (!selectedChatGroupID) return `display: none;`;
    }}
    position:fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100svh;
    width: 100vw;

    backdrop-filter: blur(20px);
    z-index: 500;
  }
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  background: var(--primarySurface);
  width: 400px;
  border-radius: 10px;
  animation: fadeIn 0.1s ease-out forwards;
`;

const RightPanelHeader = styled.div`
  padding: 15px;
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

export default function DesktopChatMainPanel() {
  const [rightPanelTab, setRightPanelTab] = useState("CONCLUSIONS");
  const rightPanelOptions = [
    {
      label: "Conclusions",
      value: "CONCLUSIONS",
    },
    {
      label: "Members",
      value: "MEMBERS",
    },
    {
      label: "Options",
      value: "OPTIONS",
    },
  ];

  let {
    selectedChatGroupIsLoading,
    selectedChatGroupData,
    selectedChatGroupID,
  } = window.globalChatSpace;

  if (selectedChatGroupIsLoading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  if (!selectedChatGroupData)
    return (
      <Container>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let grpType = selectedChatGroupData.group.type;

  if (grpType == "DIRECT_CHAT")
    return (
      <Container>
        <ChatMessagesContainer>
          <ChatMessagesPanelHeader />
          <ChatMessagesPanel />
          <ChatPanelBottomBar />
        </ChatMessagesContainer>
      </Container>
    );

  return (
    <Container>
      <ChatMessagesContainer>
        <ChatMessagesPanelHeader />
        <ChatMessagesPanel />
        <ChatPanelBottomBar />
      </ChatMessagesContainer>

      <RightPanel>
        <RightPanelHeader>
          <CustomToggle
            onChange={setRightPanelTab}
            value={rightPanelTab}
            options={rightPanelOptions}
          />
        </RightPanelHeader>

        {renderRightPanelComponent()}
      </RightPanel>
    </Container>
  );

  function renderRightPanelComponent() {
    if (rightPanelTab == "OPTIONS") return <ChatOptionsSection />;
    if (rightPanelTab == "CONCLUSIONS") return <ConclusionSection />;
    if (rightPanelTab == "MEMBERS") return <ChatMembersSection />;
  }
}
