export default function onSeen(data) {
  let { selectedChatGroupData, setSelectedChatGroupData } =
    window.globalChatSpace;

  if (!selectedChatGroupData) return;

  let newGroupData = data.chatGroup;

  // console.log("on seen rec", data);

  if (selectedChatGroupData.group._id !== data.chatGroupID) return;

  let newSelectedGrp = { ...selectedChatGroupData };

  let membersMembershipData = newSelectedGrp.membersMembershipData;

  for (let user of membersMembershipData) {
    let userID = user.userID;

    if (userID == data.sender._id) {
      // console.log("Updating last seen at....", userID);
      user.lastSeenAt = new Date().toISOString();
    }
  }

  setSelectedChatGroupData(newSelectedGrp);
}
