import { serverLine } from "../../lib/serverLine";
import universalLocalStorage from "../../lib/universalLocalStorage";

export default function sendServerAuthCode({ code, location }) {
  serverLine
    .post("/cookie", { code, location })
    .then(done)
    .catch(({ message }) => {
      console.warn(message);
      if (typeof message == "object") message = JSON.stringify(message);
      window.popupAlert(message);
    });
}

async function done(data) {
  if (data) {
    await universalLocalStorage.setItem("cottonchat-token", data.token);
    await universalLocalStorage.setItem("cottonchat-userID", data.userID);
    window.location = "/";
  }
}
