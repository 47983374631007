import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { Capacitor } from "@capacitor/core";

const firebaseConfig = {
  apiKey: "AIzaSyDGFJMKgc7qqg60-ZLKGNuA-OG2zoCYY2Q",
  authDomain: "cottonchat-7bd3e.firebaseapp.com",
  projectId: "cottonchat-7bd3e",
  storageBucket: "cottonchat-7bd3e.firebasestorage.app",
  messagingSenderId: "329168556478",
  appId: "1:329168556478:web:5681786950e3aae0d0a9ef",
  measurementId: "G-MBH3HFT7SE",
};

//Initialize Firebase
export default function initializeFirebase() {
  try {
    const platform = Capacitor.getPlatform();

    if (platform !== "web") return null;

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    const analytics = getAnalytics(app);

    let firebaseSpace = { app, messaging, analytics };

    window.firebaseSpace = firebaseSpace;
    return firebaseSpace;
  } catch (e) {
    console.warn(e);
    window.popupAlert(
      "Reload the application. Underlying system has a problem"
    );
  }
}
