import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";

import FilledButton from "../buttons/FilledButton";
import openLoginPopup from "../../controllers/openLoginPopup";
import { serverLine } from "../../lib/serverLine";
import Context from "../../../Context";
import CustomToggle from "../inputs/CustomToggle";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Info = styled.div``;

export default function LikeDislikeButtons({
  likeStatus,
  likeCount = 0,
  dislikeCount = 0,
  contentID,
  commentID,
  conclusionID,
  authorUserID,
  colorVariation,
  disableDislike,
}) {
  const { loggedInUserID } = useContext(Context);
  const [status, setStatus] = useState(likeStatus ? likeStatus : "NEUTRAL");
  const [likeCountState, setLikeCountState] = useState(0);
  const [dislikeCountState, setDislikeCountState] = useState(0);

  useEffect(() => {
    if (typeof likeCount == "number") {
      setLikeCountState(likeCount);
    }

    if (typeof dislikeCount == "number") {
      setDislikeCountState(dislikeCount);
    }
  }, []);

  let details = { contentID, commentID, conclusionID };

  let buttons = [];

  let options = [
    {
      // label: status === "LIKE" ? <AiFillLike /> : <AiOutlineLike />,
      icon: <MdArrowUpward />,
      label: likeCountState,
      value: "LIKE",
    },

    // {
    //   label: likeCountState - dislikeCountState,
    //   disabled: true,
    // },
  ];

  if (!disableDislike)
    options.push({
      // label:
      //   status === "DISLIKE" ? <AiFillDislike /> : <AiOutlineDislike />,
      icon: <MdArrowDownward />,
      label: dislikeCountState,
      value: "DISLIKE",
    });

  return (
    <CustomToggle
      colorVariation={colorVariation}
      value={status}
      onChange={(newStatus) => {
        if (newStatus == "LIKE") like();
        if (newStatus == "DISLIKE") dislike();
      }}
      options={options}
      // colorVariation={"SURFACE"}
      // btnStyle={{ borderRadius: 0, height: "40px" }}
      onHoverStyle="transform: scale(1)"
      toggleStyle={{
        // borderRadius: "10px",
        // justifyContent: "space-between",
        padding: "0",

        // overflow: "hidden",
        gap: "10px",
      }}
    />
  );

  return (
    <Buttons>
      <FilledButton
        colorVariation={colorVariation}
        onClick={like}
        icon={status === "LIKE" ? <AiFillLike /> : <AiOutlineLike />}
      >
        {likeCountState.toString()}
      </FilledButton>

      <FilledButton
        colorVariation={colorVariation}
        onClick={dislike}
        icon={status === "DISLIKE" ? <AiFillDislike /> : <AiOutlineDislike />}
      >
        {dislikeCountState.toString()}
      </FilledButton>

      {buttons}
    </Buttons>
  );

  function like() {
    if (!loggedInUserID) return openLoginPopup();
    if (authorUserID == loggedInUserID)
      return window.popupAlert("You can't like your own content");

    serverLine.post("/like-dislike", { ...details, type: "LIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      toIncrementLikeCount++;
      setStatus("LIKE");
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      setStatus("NEUTRAL");
    } else if (status === "NEUTRAL") {
      toIncrementLikeCount++;
      setStatus("LIKE");
    }

    setLikeCountState(likeCountState + toIncrementLikeCount);
    setDislikeCountState(dislikeCountState + toIncrementDislikeCount);
  }

  function dislike() {
    if (!loggedInUserID) return openLoginPopup();
    if (authorUserID == loggedInUserID)
      return window.popupAlert("You can't like your own content");
    serverLine.post("/like-dislike", { ...details, type: "DISLIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      setStatus("NEUTRAL");
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      toIncrementDislikeCount++;
      setStatus("DISLIKE");
    } else if (status === "NEUTRAL") {
      toIncrementDislikeCount++;
      setStatus("DISLIKE");
    }

    setLikeCountState(likeCountState + toIncrementLikeCount);
    setDislikeCountState(dislikeCountState + toIncrementDislikeCount);
  }
}
