import { useContext, useEffect } from "react";
import Context from "../../../Context";
import getUrlQuery from "../../controllers/getUrlQuery";
import goTo from "../../controllers/goTo";
import generateQueryPath from "./controllers/generateQueryPath";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import getUrlParams from "../../controllers/getUrlParams";
import FilledButton from "../../components/buttons/FilledButton";
import { BsBack } from "react-icons/bs";
import { DialogContent } from "@mui/material";
import PopupDialogContext from "./PopupDialogContext";
import { BiLeftArrow } from "react-icons/bi";
import { MdOutlineChevronLeft } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";

const PopupContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  /* opacity: 0.1; */
  top: 0;
  left: 0;
  z-index: 300;
`;
const PopupBackground = styled.div`
  position: absolute;
  background: var(--mainBackground);
  backdrop-filter: blur(50px);
  left: 0;
  top: 0;
  z-index: 50;
  height: 100vh;
  width: 100vw;
  animation: fadeIn 0.15s ease-out forwards;
`;
const PopupForeground = styled.div`
  width: 38vw;
  background-color: var(--primarySurface);
  display: flex;
  flex-direction: column;
  gap: 0;
  border-radius: 10px;
  max-height: calc(100vh - 50px - 50px);
  position: absolute;
  top: 50px;
  overflow-y: auto;
  left: 31vw;
  box-shadow: 0px 2px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;

  /* transform: scale(0); */
  /* opacity: 0; Start with 0 opacity */
  animation: centerScaleReveal 0.25s ease-out forwards;

  @media (max-width: 900px) {
    width: calc(100vw - 40px);
    left: 20px;
    top: 20px;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 20px;
  background-color: var(--primarySurface);
  z-index: 10;
`;
const Core = styled.div`
  padding: 20px;
`;

export default function PopupDialog() {
  const { popupComponent, setPopupComponent } = useContext(PopupDialogContext);

  useEffect(() => {
    removeRedundantPopupQuery();
  }, []);

  let urlParams = getUrlQuery();
  if (!urlParams.popupDialog) return null;
  if (!popupComponent) return null;

  let hideHeader = false;

  hideHeader = popupComponent.hideHeader;
  return (
    <PopupContainer>
      <PopupForeground>
        {hideHeader ? null : (
          <PopupHeader>
            <FilledButton onClick={goBack} icon={<FiArrowLeft />}>
              {popupComponent.title}
            </FilledButton>
            <FilledButton onClick={goBack} icon={<GrClose />}></FilledButton>
          </PopupHeader>
        )}

        <Core>{popupComponent.component}</Core>
      </PopupForeground>

      <PopupBackground onClick={goBack} />
    </PopupContainer>
  );

  function goBack() {
    setPopupComponent(null);
    goTo(-1)();
  }

  function removeRedundantPopupQuery() {
    let urlQuery = getUrlQuery();

    if (urlQuery.popupDialog) {
      delete urlQuery.popupDialog;

      let newPath = window.location.pathname;
      let newQueryPath = generateQueryPath(urlQuery);

      goTo(newPath + newQueryPath, { replace: true })();
    }
  }
}
