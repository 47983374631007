export default function updateMembershipsData({
  chatGroupID,
  freshMembershipData,
}) {
  let { selectedChatGroupData, setSelectedChatGroupData } =
    window.globalChatSpace;

  if (!selectedChatGroupData) return null;

  if (selectedChatGroupData.group._id !== chatGroupID) return null;

  let newSelectedChatGroup = { ...selectedChatGroupData };
  newSelectedChatGroup.membersMembershipData = freshMembershipData;
  setSelectedChatGroupData(newSelectedChatGroup);
}
