import { styled } from "styled-components";
import ReportsDataBox from "../../util/components/ReportsDataBox";
import limitStringLength from "../../util/controllers/limitStringLength";
import goToProfile from "../../util/controllers/goToProfile";
import getImageURL from "../../util/controllers/getImageURL";

const ReportContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  box-shadow: var(--lightShadow);
  background: var(--primarySurface);
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  background: var(--secondarySurface);

  &:hover {
    background-color: var(--primarySurface);
  }
`;

const Lines = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  flex-direction: column;
  padding: 20px;

  gap: 10px;
  border-radius: 0 10px 10px 0;
`;
const Line1 = styled.div`
  font-size: 19px;
  text-transform: capitalize;

  font-weight: 800;
`;
const Line2 = styled.div`
  opacity: 0.8;
`;

const Image = styled.img`
  height: auto;
  height: 90px;
  width: 90px;
  background-color: #fff;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default function UserBox({ item, showReportsData, onClick }) {
  let content = (
    <Container onClick={onClick ? onClick : goToProfile(item.username)}>
      <Image src={getImageURL(item.profileImage, true)} />
      <Lines>
        <Line1>{item.name}</Line1>
        <Line2>@{item.username}</Line2>
      </Lines>
    </Container>
  );

  if (showReportsData) {
    return (
      <ReportContainer>
        {content}

        <ReportsDataBox item={item} subjectType={"PROFILE"} />
      </ReportContainer>
    );
  }

  return content;
}
