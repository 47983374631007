import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import getSubPath from "../../util/controllers/getSubPath";
import CreateContent from "../createPage/CreateContent";
import { MdOutlineEdit } from "react-icons/md";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import LoadingSection from "../../util/components/LoadingSection";
import Context from "../../Context";
import { serverLine } from "../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export default function EditContentPage() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    let contentID = getSubPath(1, currentRoute);
    serverLine.get("/content/?contentID=" + contentID).then((newData) => {
      setPostData(newData);
      setLoading(false);
    });
  }, [currentRoute]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <Container>
      <Main>
        <BeautifulTitle icon={<MdOutlineEdit />}>Update Post</BeautifulTitle>
        <CreateContent contentToUpdate={postData} />
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );
}
