import { Capacitor } from "@capacitor/core";
import requestPushNotifPermissionWeb from "./requestPushNotifPermissionWeb";
import requestPushNotifPermissionApp from "./requestPushNotifPermissionApp";

export default async function requestPushNotifPermission() {
  const platform = Capacitor.getPlatform();

  if (platform == "web") return await requestPushNotifPermissionWeb();

  return await requestPushNotifPermissionApp();
}
