import ContentPage from "../pages/contentPage/ContentPage";
import CreatePage from "../pages/createPage/CreatePage";
import CreateReport from "../pages/createReportPage/CreateReport";
import DoFakeLogin from "../pages/doFakeLoginPage/DoFakeLogin";
import EditContentPage from "../pages/editContentPage/EditContentPage";
import HomePage from "../pages/homePage/HomePage";
import MoreMenuPage from "../pages/moreMenuPage/MoreMenuPage";
import ProfileEditor from "../pages/editProfilePage/ProfileEditor";
import Blocklist from "../pages/blocklistPage/Blocklist";

import SendAuthCode from "../pages/sendAuthCodePage/SendAuthCode";
import SearchPage from "../pages/searchPage/SearchPage";
import ChatPage from "../pages/chatPage/ChatPage";
import NotificationsPage from "../pages/notificationsPage/NotificationsPage";
import EditConclusionPage from "../pages/editConclusionPage/EditConclusionPage";
import ConclusionPage from "../pages/conclusionPage/ConclusionPage";
import ManageBansPage from "../pages/manageBansPage/ManageBansPage";
import ManageReportedItems from "../pages/maangeReportedItemsPage/ManageReportedItems";
import ItemReportsPage from "../pages/maangeReportedItemsPage/ItemReportsPage";

let pathVsComp = {
  "/": <HomePage />,
  "/p": <ContentPage />,
  "/conclusion": <ConclusionPage />,
  "/more-menu": <MoreMenuPage />,
  "/chat": <ChatPage />,
  "/edit-conclusion": <EditConclusionPage />,
  "/edit-content": <EditContentPage />,
  "/create-report": <CreateReport />,
  "/create": <CreatePage />,
  "/edit-profile": <ProfileEditor />,
  "/auth-redirect": <SendAuthCode />,
  "/search": <SearchPage />,
  "/fake-login": <DoFakeLogin />,
  "/notifications": <NotificationsPage />,

  //Bans & Reporting
  "/blocklist": <Blocklist />,
  "/manage-bans": <ManageBansPage />,
  "/manage-reported-items": <ManageReportedItems />,
  "/item-reports": <ItemReportsPage />,
};

export default pathVsComp;
