import { useEffect, useState } from "react";

import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion";
import styled from "styled-components";
import UserBox from "../profilePage/UserBox";
import LoadingSection from "../../util/components/LoadingSection";
import CustomToggle from "../../util/components/inputs/CustomToggle";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";
import { serverLine } from "../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export default function ManageBansPage() {
  const [data, setData] = useState();
  const [subjectType, setSubjectType] = useState("CONTENT");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    serverLine
      .get(`/banned-items/?subjectType=${subjectType}`)
      .then((newData) => {
        setData(newData);
        setLoading(false);
      });
  }, [subjectType]);

  let options = [
    { label: "Content", value: "CONTENT" },
    { label: "Conclusion", value: "CONCLUSION" },
    { label: "Profile", value: "PROFILE" },
  ];

  if (loading) return <LoadingSection />;

  let list = [];

  if (subjectType == "PROFILE") {
    list = data.map((item) => <UserBox showReportsData={true} item={item} />);
  } else if (subjectType == "CONTENT") {
    list = data.map((item) => (
      <FeedItemDiscussion ignoreBan={true} showReportsData={true} item={item} />
    ));
  } else if (subjectType == "CONCLUSION") {
    list = data.map((item) => (
      <FeedItemConclusion
        ignoreBan={true}
        colorVariation={"SURFACE"}
        showReportsData={true}
        item={item}
      />
    ));
  }

  return (
    <Container>
      <BeautifulTitle>Manage Bans</BeautifulTitle>

      <Main>
        <CustomToggle
          colorVariation={"SURFACE"}
          options={options}
          onChange={(newType) => {
            setData([]);
            setSubjectType(newType);
          }}
          value={subjectType}
        />

        <List>{list}</List>
      </Main>
    </Container>
  );
}
