import { useEffect, useState } from "react";
import Context from "../../../Context.js";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

import getUserID from "../../controllers/getUserID.js";

import { serverLine } from "../../lib/serverLine.js";
import logout from "../../controllers/logout.js";

import AskBasicInfo from "../../../util/components/onboarding/AskBasicInfo.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import updateRouteState from "../../controllers/updateRouteState.js";

import YouAreBanned from "../../../pages/maangeReportedItemsPage/components/YouAreBanned.js";

import { ThemeProvider, createTheme } from "@mui/material";

import LoadingSection from "../../components/LoadingSection.js";
import PopupDialogProvider from "../../lib/popupDialog/PopupDialogProvider.js";
import initializeChat from "../../../pages/chatPage/controllers/socket/initializeChat.js";

import { App as CapacitorApp } from "@capacitor/app";
import universalLocalStorage from "../../lib/universalLocalStorage.js";
import initializeFirebase from "../../../services/firebase/pushNotification/initializeFirebase.js";
import EnablePushNotificationCard from "../../../services/firebase/pushNotification/components/EnablePushNotificationCard.js";
import usePopupDialog from "../../lib/popupDialog/usePopupDialog.js";

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);

  const [globalLikeStatus, setGlobalLikeStatus] = useState({});

  const alert = (message) => {
    toast(message);
  };

  const [colorMode, setColorMode] = useState("LIGHT");
  const [loggedInUser, setLoggedInUser] = useState(null);

  const [notificationData, setNotificationData] = useState({
    general: 0,
  });

  const [followingUserIDs, setFollowingUserIDs] = useState([]);
  const [allProfiles, setAllProfiles] = useState(null);
  let currentPath = window.location.pathname + window.location.search;
  const [currentRoute, setCurrentRoute] = useState(currentPath);
  let initialNonPopupRoute = currentPath;
  const [nonPopupRoute, setNonPopupRoute] = useState(initialNonPopupRoute);

  //chat start
  const [chatMembershipAndNotifications, setChatMembershipAndNotifications] =
    useState(null);
  const [selectedChatGroupID, setSelectedChatGroupID] = useState(null);
  const [typingData, setTypingData] = useState({});
  const [chatGroupsAreLoading, setChatGroupsAreLoading] = useState(true);
  const [selectedChatGroupIsLoading, setSelectedChatGroupIsLoading] =
    useState(false);
  const [chatGroupsType, setChatGroupsType] = useState("GENERAL");
  const [selectedChatGroupData, setSelectedChatGroupData] = useState(null);
  const [mutedChatGroups, setMutedChatGroups] = useState([]);
  const [chatGroups, setChatGroups] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [friendsLastSeenAt, setFriendsLastSeenAt] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [oppositeMembers, setOppositeMembers] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [pushNotificationDecision, setPushNotificationDecision] =
    useState(null);

  let isMobile = screenWidth < 900;

  let globalChatSpace = {
    loggedInUser,
    loggedInUserID,
    //
    replyingTo,
    setReplyingTo,
    //
    chatGroupsType,
    setChatGroupsType,
    //
    mutedChatGroups,
    setMutedChatGroups,
    //
    oppositeMembers,
    setOppositeMembers,
    //
    chatMembershipAndNotifications,
    setChatMembershipAndNotifications,
    //
    selectedChatGroupID,
    setSelectedChatGroupID,
    //
    typingData,
    setTypingData,
    //
    chatGroupsAreLoading,
    setChatGroupsAreLoading,
    //
    selectedChatGroupIsLoading,
    setSelectedChatGroupIsLoading,
    //
    chatGroups,
    setChatGroups,
    //
    selectedChatGroupData,
    setSelectedChatGroupData,
    //
    isConnected,
    setIsConnected,
    //
    friendsLastSeenAt,
    setFriendsLastSeenAt,
  };

  window.doInitialLoad = doInitialLoad;
  window.globalChatSpace = globalChatSpace;
  //chat end

  window.setNonPopupRoute = setNonPopupRoute;

  window.setCurrentRoute = setCurrentRoute;

  window.doAlert = alert;
  window.loggedInUserID = loggedInUserID;
  window.loggedInUser = loggedInUser;
  window.updateColorMode = updateColorMode;
  window.updateLoggedInUser = updateLoggedInUser;

  useEffect(() => {
    initializeFirebase();
    GoogleAuth.initialize({
      clientId:
        "991911447615-vkp6b69shojc7jk2evhdjcdnaqosabnr.apps.googleusercontent.com",
      scopes: ["profile", "email"],
      grantOfflineAccess: true,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.notifInterval) {
      window.clearInterval(window.notifInterval);
    }

    if (loggedInUserID) {
      updateNotificationData();

      window.notifInterval = window.setInterval(() => {
        updateNotificationData();
      }, 30 * 1000);
    }
  }, [loggedInUserID]);

  useState(() => {
    let theFunc = (theEvent) => {
      let newURl = window.location.pathname + window.location.search;

      updateRouteState(newURl);
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  useEffect(() => {
    doInitialLoad();
  }, []);

  useEffect(() => {
    loadOnce();
  }, []);

  if (loggedInUserID) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      if (loggedInUser.isBanned) children = <YouAreBanned />;
      if (!loggedInUser.onboarding) children = <AskBasicInfo />;
      if (loggedInUser.onboarding)
        if (!loggedInUser.onboarding.basicInfoAdded)
          children = <AskBasicInfo />;
    }
  }

  // children = <AskBasicInfo />;

  const themeMUI = createTheme({
    palette: {
      mode: colorMode.toLowerCase(),
      primary: {
        main: "#e8eaf6",
      },
      secondary: {
        main: "#8bc34a",
      },
    },
  });

  let sharedState = {
    pushNotificationDecision,
    setPushNotificationDecision,
    doInitialLoad,
    globalLikeStatus,
    updateGlobalLikeStatus,
    nonPopupRoute,
    setNonPopupRoute,
    currentRoute,
    setLoggedInUser,
    loggedInUserID,

    popupAlert: alert,
    updateLoggedInUser: updateLoggedInUser,
    loggedInUser: loggedInUser,
    updatePushNotificationDecision,
    updateColorMode,
    colorMode,
    followingUserIDs,
    setFollowingUserIDs,
    //
    allProfiles,
    setAllProfiles,
    //
    notificationData,
    updateNotificationData,
    isMobile,
    screenWidth,
    //Chat start
    globalChatSpace,
  };

  window.sharedState = sharedState;

  window.popupAlert = alert;
  return (
    <Context.Provider value={sharedState}>
      <PopupDialogProvider>
        <ThemeProvider theme={themeMUI}>
          {children}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </ThemeProvider>
      </PopupDialogProvider>
    </Context.Provider>
  );

  async function loadOnce() {
    loadNotificationDecision();
    loadInitialColorMode();
  }

  async function updatePushNotificationDecision(value) {
    await universalLocalStorage.setItem(
      "cottonchat-push-notification-decision",
      value
    );
    setPushNotificationDecision(value);
  }

  async function loadNotificationDecision() {
    let pushNotificationDec = await universalLocalStorage.getItem(
      "cottonchat-push-notification-decision"
    );
    setPushNotificationDecision(pushNotificationDec);
  }

  function loadInitialColorMode() {
    universalLocalStorage.getItem("cottonchat-color-mode").then((newStatus) => {
      console.log("newStatus", newStatus);
      if (!newStatus) newStatus = "LIGHT";

      updateColorMode(newStatus);
    });
  }

  async function doInitialLoad() {
    // return;
    let userID = await getUserID();

    if (userID) {
      setLoggedInUserID(userID);

      updateLoggedInUser();
      initializeChat();
    } else {
      setLoggedInUser(null);
      setLoggedInUserID(false);
    }
  }

  function updateNotificationData() {
    // console.log(loggedInUser);
    serverLine.get("/notification-count").then(setNotificationData);
  }

  function updateGlobalLikeStatus(items) {
    let oldStatus = { ...window.nonReactLikeStatus };

    for (let item of items) {
      oldStatus[item._id] = {
        likeStatus: item.likeStatus,
        likeCount: item.likeCount,
        dislikeCount: item.dislikeCount,
      };
    }

    window.nonReactLikeStatus = oldStatus;
    setGlobalLikeStatus(oldStatus);
    //This makes sure that between frequent react updates value is not lost
  }

  async function updateColorMode(newStatus) {
    setColorMode(newStatus);
    setColors(newStatus);
    await universalLocalStorage.setItem("cottonchat-color-mode", newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then((data) => {
        if (data) {
          if (data.you) setLoggedInUser(data.you);
          if (data.allProfiles) setAllProfiles(data.allProfiles);
          if (data.followingUserIDs) setFollowingUserIDs(data.followingUserIDs);
        }
      });
    } catch (e) {
      alert.show(e.message);
      // console.log("First Fetch Error----------");
      logout();
    }
  }

  function setColors(newVal) {
    let lightTheme = {
      //Background
      popupBackground: "linear-gradient(#c4d6f6, #a0a9d7)",
      mainBackground: "linear-gradient(#DDE6F6, #C2CAF1)",
      mainBackgroundText: "#153E87",

      //Surface
      primarySurface: "#ffffff", //Main cards & panels
      secondarySurface: "rgba(255,255,255,0.5)", //Secondary cards like chat group not selected
      surfaceText: "#153E87",

      //Buttons
      superPrimaryInput: "#8fbaff", // Main buttons
      primaryInput: "#B7D1FB", // Main buttons
      secondaryInput: "#DBE8FF", // Main buttons
      inputText: "#153E87",

      //Others
      accentLightColor: "#5c8ada",
      accentColor: "#5c8ada",
      mobileHeaderBorder: "#9fbae68f",
      borderRadius: "13px",
      logo: "url('/logo/light-mode/logo.svg')",
      textLogo: "url('/logo/light-mode/text-logo.svg')",
      lightShadow: "1px 10px 40px 0 rgba(0,0,0,0.2)",
    };

    let darkTheme = {
      //Background
      popupBackground: "linear-gradient(rgba(3,3,3), rgba(10,10,10))",
      mainBackground: "linear-gradient(rgba(3,3,3), rgba(10,10,10))",
      mainBackgroundText: "#ffffff",

      //Surface
      primarySurface: "rgb(25 25 29)", //Main cards & panels
      secondarySurface: "rgba(17,17,17)", //Secondary cards like chat group not selected
      surfaceText: "#ffffff",

      //Buttons
      superPrimaryInput: "rgba(10,10,0)", // Main buttons
      primaryInput: "rgba(0,0,0)", // Main buttons
      secondaryInput: "rgba(14,14,14)", // Main buttons
      inputText: "#ffffff",

      //Others
      accentColor: "#a0b5da",
      accentLightColor: "#B7D1FB",
      mobileHeaderBorder: "#222222",
      borderRadius: "13px",
      logo: "url('/logo/dark-mode/logo.svg')",
      textLogo: "url('/logo/dark-mode/text-logo.svg')",
      lightShadow: "1px 10px 100px 0 rgba(0,0,0,0.2)",
    };

    let colors = lightTheme;

    if (newVal) {
      if (newVal === "DARK") colors = darkTheme;
    }

    document.documentElement.style.setProperty(
      "--popupBackground",
      colors.popupBackground
    );

    document.documentElement.style.setProperty(
      "--mainBackground",
      colors.mainBackground
    );

    document.documentElement.style.setProperty(
      "--mainBackgroundText",
      colors.mainBackgroundText
    );

    document.documentElement.style.setProperty(
      "--primarySurface",
      colors.primarySurface
    );

    document.documentElement.style.setProperty(
      "--secondarySurface",
      colors.secondarySurface
    );

    document.documentElement.style.setProperty(
      "--surfaceText",
      colors.surfaceText
    );

    document.documentElement.style.setProperty(
      "--superPrimaryInput",
      colors.superPrimaryInput
    );

    document.documentElement.style.setProperty(
      "--primaryInput",
      colors.primaryInput
    );

    document.documentElement.style.setProperty(
      "--secondaryInput",
      colors.secondaryInput
    );

    document.documentElement.style.setProperty("--inputText", colors.inputText);

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--accentLightColor",
      colors.accentLightColor
    );

    document.documentElement.style.setProperty(
      "--borderRadius",
      colors.borderRadius
    );

    document.documentElement.style.setProperty(
      "--borderRadius",
      colors.borderRadius
    );

    document.documentElement.style.setProperty(
      "--mobileHeaderBorder",
      colors.mobileHeaderBorder
    );

    document.documentElement.style.setProperty("--logo", colors.logo);
    document.documentElement.style.setProperty("--textLogo", colors.textLogo);
    document.documentElement.style.setProperty(
      "--lightShadow",
      colors.lightShadow
    );
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParamsForm = new URLSearchParams(queryString);
    return urlParamsForm.get(field);
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }
}

export default Boilerplate;
