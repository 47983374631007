import { styled } from "styled-components";
import getImageURL from "../../../../../util/controllers/getImageURL";
import FilledButton from "../../../../../util/components/buttons/FilledButton";
import { MdOutlineAddReaction } from "react-icons/md";
import { GoReply } from "react-icons/go";
import ReplyOfBox from "./ReplyOfBox";
import AddMessageReaction from "../AddMessageReaction";
import { useContext } from "react";
import Context from "../../../../../Context";
import usePopupDialog from "../../../../../util/lib/popupDialog/usePopupDialog";
import MessageReactionsBox from "./MessageReactionsBox";
import capitalizeFirstLetter from "../../../../../util/controllers/capitalizeFirstLetter";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  /* text-transform: capitalize; */
  width: 100%;

  position: relative;
  font-size: 12px;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
  animation-delay: 0.2s;
`;

export default function InfoMessage({ message }) {
  return <Container>{capitalizeFirstLetter(getLine())}</Container>;

  function getLine() {
    let author = message.messageAuthor;
    let subjectMember = message.subjectMember;

    let type = message.type;
    if (type == "MEMBER_JOINED")
      return `${author.username} has joined the conversation`;
    if (type == "MEMBER_LEFT")
      return `${author.username} has left the conversation`;
    if (type == "KICK_OUT") return `${subjectMember.username} has been removed`;
    if (type == "VOTE_OUT")
      return `${subjectMember.username} has received a yellow card`;
    if (type == "VOTE_OUT_REVOKED")
      return `${subjectMember.username}'s yellow card has been revoked`;
  }
}
