export default function getChatUiData(chatGroup, oppositeMember) {
  let { oppositeMembers, loggedInUserID } = window.globalChatSpace;

  let image = null;
  let title = chatGroup.contentTitle;
  let link = null;
  let username = null;

  if (chatGroup.type === "DIRECT_CHAT") {
    // console.log("oppositeMember", oppositeMember);
    //we use opposite member rather than global.oppositeMembers[someID]
    //because when a new direct chat is created
    //It is not added to the chatGroupsPanel

    if (oppositeMember) {
      link = "/" + oppositeMember.username;
      username = oppositeMember.username;
      title = oppositeMember.name;
      image = oppositeMember.profileImage;
    } else {
      title = "CottonChat User";
    }
  } else {
    link = "/p/" + chatGroup.contentSlugID;
  }

  return { title, image, username, link };
}
