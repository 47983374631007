import getObjectID from "../../../../util/controllers/getObjectID";
import sendMessage from "../socket/send/sendMessage";
import getChatGroup from "./getChatGroup";
import ObjectID from "bson-objectid";

export default async function sendDirectChatWithoutOpening({
  user,
  textMessage,
}) {
  let userID = user._id;
  let { chatGroup } = await getChatGroup({
    userID: userID,
    chatGroupType: "DIRECT_CHAT",
  });

  let messageDoc = { _id: getObjectID(), message: { text: textMessage } };

  sendMessage({
    messageDoc,
    chatGroup: chatGroup,
    oppositeMember: user,
  });
}
