import styled from "styled-components";

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* text-transform: uppercase; */
  align-items: center;
  font-family: "Questrial";
`;

const Line1 = styled.div`
  font-weight: 300;
  font-size: 40px;
  font-family: inherit;
`;

const Line2 = styled.div`
  font-weight: 300;
  font-size: 43px;
  font-family: inherit;
`;

const Line3 = styled.div`
  font-weight: 300;
  font-size: 15px;
  font-family: inherit;
`;

export default function LoggedOutHeaderGraphic() {
  return null;
  return (
    <Lines>
      <Line1>DISCUSS</Line1>
      <Line2>DEBATE</Line2>
      <Line3>& make new friends</Line3>
    </Lines>
  );
}
