import styled from "styled-components";
import getImageURL from "../controllers/getImageURL";

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 500px;
  border: 1px solid var(--accentLightColor);
`;

export default function ProfilePicture({ size, user, height, width }) {
  let style = {};

  if (size) {
    style = { height: size, width: size };
  }

  if (height && width) {
    style = { height: height, width: width };
  }

  return (
    <ProfileImage style={style} src={getImageURL(user.profileImage, true)} />
  );
}
