export default function getLinkToS3(fileName) {
  let environment = "production";

  if (window.location.host.indexOf("localhost") !== -1) {
    environment = "development";
  }

  if (window.location.host.indexOf("192.") !== -1) {
    environment = "development";
  }

  let base = "http://simple-and-nice.s3.ap-south-1.amazonaws.com";

  base = "https://d3agq5fmkvjuh7.cloudfront.net";

  return `${base}/${environment}/cottonchat/${fileName}`;
}
