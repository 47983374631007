import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";

export default function getFriends() {
  const { chatGroups, loggedInUserID, selectedChatGroupData } =
    window.globalChatSpace;

  if (!chatGroups) return [];

  let friends = [];

  for (let item of chatGroups) {
    if (item.type == "CONTENT_CHAT") continue;

    let oppositeMemberID = getOppositeMemberID({
      chatGroup: item,
      loggedInUserID,
    });

    if (!friends.includes(oppositeMemberID)) {
      friends.push(oppositeMemberID);
    }
  }

  if (selectedChatGroupData) {
    for (let item of selectedChatGroupData.membersMembershipData) {
      friends.push(item.userID);
      // console.log("pushing....", item.userID);
    }
  }

  return friends;
}
