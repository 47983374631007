import { serverLine } from "../../../../../util/lib/serverLine";
import messageTransactionHappened from "../message/messageTransactionHappened";

export default async function sendMessage({
  messageDoc,
  chatGroup,
  oppositeMember,
  onSuccess,
}) {
  let chatGroupID = chatGroup._id;
  let { loggedInUser, replyingTo, setReplyingTo } = window.globalChatSpace;

  console.log(chatGroupID, "chatGroupID");
  messageDoc.replyOf = replyingTo;
  setReplyingTo(null);

  try {
    messageTransactionHappened({
      sender: loggedInUser,
      sentOrReceived: "SENT",
      messageDoc,
      chatGroup: chatGroup,
      oppositeMember: oppositeMember,
    });

    console.log("chatGroupID", chatGroupID);
    await serverLine.post("/message", {
      messageDoc,
      chatGroupID: chatGroupID,
    });

    //We send message after saving because of two reasons
    //Enforce blocking
    //Avoid the case where reaction is being saved but the original post is not yet saved
    window.chatSocket.emit("message", {
      messageDoc: messageDoc,
      chatGroupID: chatGroupID,
    });

    markAsSent(messageDoc._id);

    if (onSuccess) onSuccess();
  } catch (e) {
    console.warn(e);
    window.popupAlert(e.message);
  }
}

function markAsSent(messageID) {
  let { selectedChatGroupData, setSelectedChatGroupData } =
    global.globalChatSpace;

  if (!selectedChatGroupData) return null;

  let newSelectedGrp = { ...selectedChatGroupData };

  for (let item of newSelectedGrp.messages) {
    if (item._id == messageID) {
      item.isSent = true;
    }
  }

  setSelectedChatGroupData(newSelectedGrp);
}
