import { MdAdd, MdCall, MdMessage, MdMinimize } from "react-icons/md";

import CustomButton from "./inputs/CustomButton.js";
import { useContext } from "react";
import Context from "../../Context.js";

import openLoginPopup from "../controllers/openLoginPopup.js";
import FilledButton from "./buttons/FilledButton.js";
import { serverLine } from "../lib/serverLine.js";

export default function FollowButton({ userID, style }) {
  const { loggedInUserID, followingUserIDs, setFollowingUserIDs } =
    useContext(Context);

  const hasFollowed = getFollowStatus();

  if (loggedInUserID == userID) return null;

  return (
    <FilledButton
      style={style}
      onClick={followButton}
      icon={hasFollowed ? null : <MdAdd />}
    >
      {hasFollowed ? "Following" : "Follow"}
    </FilledButton>
  );

  function getFollowStatus() {
    return followingUserIDs.includes(userID);
  }

  function followButton() {
    if (!loggedInUserID) return openLoginPopup();

    serverLine.post("/follow", { receiverUserID: userID });

    let theList = [];

    theList = [...followingUserIDs];

    if (theList.includes(userID)) {
      let index = theList.indexOf(userID);
      theList.splice(index, 1);
    } else {
      theList.push(userID);
    }

    setFollowingUserIDs(theList);
  }
}
