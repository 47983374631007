import { styled } from "styled-components";

import { useContext, useEffect, useState } from "react";
import getSubPath from "../../util/controllers/getSubPath";
import { MdOutlineEdit } from "react-icons/md";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import LoadingSection from "../../util/components/LoadingSection";
import Context from "../../Context";
import FilledButton from "../../util/components/buttons/FilledButton";
import goTo from "../../util/controllers/goTo";
import { serverLine } from "../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const WriteTitle = styled.textarea`
  height: 130px;
  resize: none;
  width: 38vw;
  border: none;
  color: var(--color);
  padding: 25px;
  background-color: var(--primarySurface);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  /* text-align: center; */

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export default function EditConclusionPage() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    let conclusionID = getSubPath(1, currentRoute);
    serverLine
      .get("/conclusion/?conclusionID=" + conclusionID)
      .then((newData) => {
        setPostData(newData);
        setText(newData.data.text);
        setLoading(false);
      });
  }, [currentRoute]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <Container>
      <Main>
        <BeautifulTitle icon={<MdOutlineEdit />}>
          Update Conclusion
        </BeautifulTitle>
        <WriteTitle
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <FilledButton colorVariation={"SURFACE"} onClick={update}>
          Update
        </FilledButton>
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );

  function update() {
    setLoading(true);

    serverLine
      .patch("/conclusion", {
        conclusionID: postData._id,
        changes: {
          data: { text },
        },
      })
      .then((newData) => {
        setLoading(false);
        goTo("/conclusion/" + newData._id)();
      });
  }
}
