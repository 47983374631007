import styled from "styled-components";
import { useEffect, useState } from "react";
import LoadingSection from "../../util/components/LoadingSection.js";
import BrandContainer from "../../util/components/brand/BrandContainer.js";
import { serverLine } from "../../util/lib/serverLine.js";
import universalLocalStorage from "../../util/lib/universalLocalStorage.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* margin-top: 100px; */
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100vw;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;

  border-radius: 15px;

  background: var(--primarySurface);
  border: 1px solid var(--foreground);
`;

function DoFakeLogin() {
  let [error, setError] = useState(false);

  useEffect(() => {
    // console.log("req sending auth code");
    sendCode();
  }, []);

  if (error) return <ErrorBox>{error}</ErrorBox>;
  return (
    <Container>
      <Logo>
        <BrandContainer />
      </Logo>

      <LoadingSection />
    </Container>
  );

  async function done(data) {
    if (data) {
      await universalLocalStorage.setItem("cottonchat-token", data.token);
      await universalLocalStorage.setItem("cottonchat-userID", data.userID);
      window.location = "/";
    }
  }

  function sendCode() {
    // console.log("sending auth code");

    serverLine
      .post("/fake-login")
      .then(done)
      .catch(({ message }) => {
        console.warn(message);
        if (typeof message == "object") message = JSON.stringify(message);
        setError(message);
      });
  }
}

export default DoFakeLogin;
