import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";
import sendGoogleAccessToken from "../../util/controllers/loginWithGoogle/sendGoogleAccessToken";

export default function goToAuthScreen({ setLoading }) {
  return async () => {
    setLoading(true);
    const platform = Capacitor.getPlatform();

    console.log(platform);

    try {
      await doAppAuth();
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  };

  // if (platform === "android" || platform === "android") {
  //   doAppAuth();
  // } else if (platform === "web") {
  //   doWebAuth();
  // } else {
  //   console.log("Unknown platform");
  // }
}

async function doAppAuth() {
  const response = await GoogleAuth.signIn();
  console.log(response);
  let refreshData = await GoogleAuth.refresh();

  sendGoogleAccessToken(refreshData.accessToken);
}

// function doWebAuth() {
//   const clientId =
//     "1012677660729-nd0ael59u41657lkq5v0itk029u2ch7e.apps.googleusercontent.com";
//   const redirectUri = `${window.location.origin}/auth-redirect`;
//   const responseType = "code";
//   const scope = "profile email openid";

//   // return console.log(redirectUri);

//   let theLink = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=offline&include_granted_scopes=true`;

//   // console.log(theLink);
//   window.location = theLink;
// }
