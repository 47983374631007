import uploadPushNotificationToken from "./uploadPushNotificationToken";

import { PushNotifications } from "@capacitor/push-notifications";

export default async function requestPushNotifPermissionApp() {
  console.log("Initializing HomePage");
  let { updatePushNotificationDecision } = window.sharedState;

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      window.popupAlert(
        "Permission has been denied. Please! enable it from outside settings."
      );
      updatePushNotificationDecision("DENIED");
      // Show some error
    }
  });

  PushNotifications.addListener("registration", (token) => {
    // alert('Push registration success, token: ' + token.value);
    uploadPushNotificationToken({ tokenType: "ANDROID", token: token.value });
    updatePushNotificationDecision("GRANTED");
    window.popupAlert("Push notifications enabled");
  });

  PushNotifications.addListener("registrationError", (error) => {
    // alert("Error on registration: " + JSON.stringify(error));
    window.popupAlert(
      "Permission has been denied. Please! enable it from outside settings."
    );
    updatePushNotificationDecision("DENIED");
  });

  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    alert("Push received: " + JSON.stringify(notification));
  });

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      alert("Push action performed: " + JSON.stringify(notification));
    }
  );
}
