import { AiOutlineDown } from "react-icons/ai";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px;
  align-items: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;

  font-size: 19px;
  color: var(--color);
  width: auto;
  border-radius: 100px;
  background: var(--primarySurface);
  margin-bottom: 0px;
  box-shadow: 1px 10px 10px 0 rgba(0, 0, 0, 0.1);
  animation: centerScaleReveal 0.25s ease-out forwards;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  align-items: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  padding: 13px 30px;
  font-size: 19px;
  color: var(--color);
  text-transform: capitalize;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  text-align: left;
  justify-content: flex-start;
  /* opacity: 0.8; */
  position: relative;
  white-space: nowrap;
`;

const MoreButton = styled.div`
  display: flex;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  border-left: 1px solid var(--translucent);
  padding: 20px;
`;

export default function BeautifulTitle({ children, icon }) {
  return (
    <Container>
      <Main>
        {icon && <Icon>{icon}</Icon>}
        <Text>{children}</Text>
      </Main>

      {/* <MoreButton>
        <AiOutlineDown />
      </MoreButton> */}
    </Container>
  );
}
