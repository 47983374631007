import { useContext, useState } from "react";
import { serverLine } from "../../lib/serverLine";
import styled from "styled-components";
import Context from "../../../Context";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import getHowOldInString from "../../controllers/utils/getHowOldInString";
import limitStringLength from "../../controllers/limitStringLength";
import { BsChat, BsThreeDotsVertical } from "react-icons/bs";
import { GoReport } from "react-icons/go";
import openChat from "../../../pages/chatPage/controllers/api/openChat";
import { MoonLoader } from "react-spinners";
import ReportsDataBox from "../ReportsDataBox";
import CustomButton from "../inputs/CustomButton";
import FilledButton from "../buttons/FilledButton";
import UnderlinedButton from "../buttons/UnderlinedButton";
import VoteButtons from "../voteButtons/VoteButtons";
import usePopupDialog from "../../lib/popupDialog/usePopupDialog";
import Options from "../inputs/Options";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import LikeDislikeButtons from "../voteButtons/LikeDislikeButtons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  border-radius: var(--borderRadius);
  padding: 20px 30px;
  background: var(--primarySurface);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

  animation: centerScaleReveal 0.3s ease-out forwards;

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

const PrimaryLine = styled.div`
  font-size: 21px;
  font-weight: 900;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Description = styled.div`
  font-size: 17px;
  opacity: 0.8;
  font-weight: 600;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const SecondaryLine = styled.div`
  font-size: 13px;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-top: 0;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 5px;
  }
`;

const Info = styled.span`
  text-transform: capitalize;
  font-weight: 600;

  font-size: 13px;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const ForContentPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function FeedItemDiscussion({
  item,
  showReportsData,
  forContentPage,
  ignoreBan,
}) {
  const { popupAlert, loggedInUserID } = useContext(Context);
  const [openingChatPage, setOpeningChatPage] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);
  const { closePopup, showPopup } = usePopupDialog();

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (isDeleted || item.isArchived) return <Container>Item Deleted</Container>;

  let chatInputText = "Join Chat";
  if (item.chatGroupMembership) chatInputText = "Open Chat";

  let desc = "";

  if (item.description) {
    if (item.description.text) desc = item.description.text;
  }

  if (item.isBanned && !ignoreBan)
    return <Container>Item has been banned</Container>;

  let conclusionText = "Conclusions";

  if (item.conclusionCount > 1)
    conclusionText = `${item.conclusionCount} Conclusions`;

  let core = (
    <>
      <Main>
        <PrimaryLine>{capitalizeFirstLetter(item.title)}</PrimaryLine>

        {desc ? <Description>{desc}</Description> : null}
      </Main>

      <SecondaryLine>
        <UnderlinedButton href={`/${item.author.username}`}>
          {item.author.username}
        </UnderlinedButton>

        <Info>Rooms: {item.roomsCreated}</Info>
        <Info>Room Capacity: {item.maxUsersPerRoom}</Info>
        {item.type == "PRIVATE" ? <Info> Private </Info> : null}
      </SecondaryLine>

      <Buttons>
        <FilledButton
          style={{ background: "var(--primaryInput)" }}
          isLoading={openingChatPage}
          onClick={gotoChatPage}
        >
          {chatInputText}
        </FilledButton>

        {!forContentPage && (
          <FilledButton onClick={goTo(`/p/${item.slugID}/?popup=content`)}>
            {conclusionText}
          </FilledButton>
        )}

        {/* <LikeDislikeButtons
          likeStatus={item.likeStatus}
          likeCount={item.likeCount}
          dislikeCount={item.dislikeCount}
          contentID={item._id}
          authorUserID={item.authorUserID}
          colorVariation={"ACCENT"}
        /> */}

        <FilledButton
          mobileStyle={{ width: "20px" }}
          style={{ width: "20px" }}
          onClick={showContentMenu}
          icon={<BsThreeDotsVertical />}
        />
      </Buttons>
    </>
  );

  if (forContentPage) {
    return <ForContentPage>{core}</ForContentPage>;
  }

  return (
    <Container>
      {core}

      {showReportsData ? (
        <ReportsDataBox item={item} subjectType={"CONTENT"} />
      ) : null}
    </Container>
  );

  function showContentMenu() {
    let options = [{ label: "Copy Link", onClick: copyLinkToClipboard }];

    if (loggedInUserID == item.authorUserID) {
      options = [
        ...options,
        { label: "Edit", onClick: goTo(`/edit-content/${item._id}`) },
        { label: "Delete", onClick: deleteContent },
      ];
    } else {
      options = [
        ...options,
        {
          label: "Report",
          onClick: goTo(
            `/create-report/?subjectType=content&subjectID=${item._id}`
          ),
        },
      ];
    }

    showPopup({ title: "options", component: <Options options={options} /> });
  }

  async function copyLinkToClipboard() {
    window.popupAlert("Link copied to clipboard");
    await window.navigator.clipboard.writeText(
      "https://cottonchat.com/p/" + item.slugID
    );
  }

  function deleteContent() {
    let options = [
      { label: "Yes! Delete Post", onClick: doIt },
      {
        label: "No",
        onClick: () => {
          closePopup();
        },
      },
    ];

    showPopup({ title: "options", component: <Options options={options} /> });

    function doIt() {
      popupAlert("Post Deleted!");
      setIsDeleted(true);
      closePopup();
      serverLine.delete(`/content/?contentID=${item._id}`);
    }
  }

  async function gotoChatPage() {
    try {
      if (item.chatGroupMembership) {
        return goTo(
          `/chat/${item.chatGroupMembership.chatGroupID}/?popup=chat`
        )();
      }

      setOpeningChatPage(true);
      await openChat({ chatGroupType: "CONTENT_CHAT", contentID: item._id });
      setOpeningChatPage(false);
    } catch (e) {
      console.warn(e);
      window.popupAlert(e.message);
      setOpeningChatPage(false);
    }
  }
}

function parseCategory(cate) {
  return capitalizeFirstLetter(cate.toLowerCase().replace("_", " "));
}
