import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import getSubPath from "../../util/controllers/getSubPath";
import RenderContent from "../contentPage/RenderContent";
import LoadingSection from "../../util/components/LoadingSection";
import Context from "../../Context";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";
import { serverLine } from "../../util/lib/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 38vw;
  /* background: var(--primarySurface); */
  min-height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  border-radius: 10px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px;
  }
`;

export default function ConclusionPage() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    let conclusionID = getSubPath(1, currentRoute);
    serverLine
      .get("/conclusion/?conclusionID=" + conclusionID)
      .then((newData) => {
        setItemData(newData);
        setLoading(false);
      });
  }, [currentRoute]);

  if (!itemData || loading) return <LoadingSection />;

  return (
    <Container>
      <Main>
        <FeedItemConclusion
          colorVariation={"SURFACE"}
          showContentDetails={true}
          item={itemData}
        />
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );
}
