import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import {
  BsChevronLeft,
  BsThreeDots,
  BsThreeDotsVertical,
} from "react-icons/bs";
import OnlineBubble from "../common/OnlineBubble";

import readTypingData from "../../controllers/ui/readTypingData";
import getChatUiData from "../../controllers/ui/getChatUiData";
import getOppositeMemberID from "../../../../util/controllers/getOppositeMemberID";
import loadSelectedChatGroup from "../../controllers/api/loadSelectedChatGroup";

import leaveChatGroup from "../../controllers/api/leaveChatGroup";
import BlockUnblockInterface from "../../../profilePage/BlockUnblockInterface";

import limitStringLength from "../../../../util/controllers/limitStringLength";
import getOnlineOfflineStatus from "../../../../util/controllers/socket/getOnlineOfflineStatus";
import SemanticButton from "../../../../util/components/SemanticButton";
import getProfileLink from "../../../../util/controllers/getProfileLink";
import goToProfile from "../../../../util/controllers/goToProfile";
import CustomButton from "../../../../util/components/inputs/CustomButton";

import goTo from "../../../../util/controllers/goTo";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import getImageURL from "../../../../util/controllers/getImageURL";
import usePopupDialog from "../../../../util/lib/popupDialog/usePopupDialog";
import Options from "../../../../util/components/inputs/Options";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import DropDownLocationInput from "../../../../util/components/DropDownLocationInput";
import DropDownInput from "../../../../util/components/inputs/DropDownInput";
import muteSelectedChatGroup from "../../controllers/api/muteSelectedChatGroup";
import getChatGroupMuteStatus from "../../controllers/ui/getChatGroupMuteStatus";
import { MdMore } from "react-icons/md";
import ChatOptionsSection from "../chatOptionsSection/ChatOptionsSection";

const TopPart = styled.div`
  padding: 20px 20px;
  /* height: 150px; */
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--secondaryInput);
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    gap: 15px;
    padding: 10px;

    height: 65px;
  }
`;

const TopProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 15px;

  @media (max-width: 900px) {
  }
`;

const TopProfileLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex: 1;
  transition: all 0.15s ease-in;
`;

const Image = styled.img`
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 100px;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;

  white-space: nowrap; /* Prevents line breaks */
  overflow: hidden; /* Hides the overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis (…) to indicate overflow */
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    font-size: 13px;
    text-align: center;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  @media (max-width: 900px) {
    gap: 5px;
    align-items: center;
  }
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.7;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  white-space: nowrap; /* Prevents line breaks */
  overflow: hidden; /* Hides the overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis (…) to indicate overflow */

  @media (max-width: 900px) {
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const Username = styled.div`
  &:hover {
    text-decoration: underline;
    /* transform: scale(0.9); */
  }
`;

const BackButton = styled.div`
  /* opacity: 0.5; */
  display: none;

  @media (max-width: 900px) {
    /* display: none; */
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    font-size: 21px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HeadingInfo = styled.div``;

const rightPanelOptions = [
  {
    label: "Messages",
    value: "MESSAGES",
  },
  {
    label: "Conclusions",
    value: "CONCLUSIONS",
  },
  {
    label: "Members",
    value: "MEMBERS",
  },
  {
    label: "Options",
    value: "OPTIONS",
  },
];

const DirectChatImageAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 7px 10px;
    background-color: var(--secondaryInput);
  }

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DirectChatText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  font-size: 13px;
  padding-right: 5px;

  @media (max-width: 900px) {
    font-size: 11px;
    gap: 4px;
  }
`;

const DirectChatTitle = styled.div`
  font-weight: 900;
`;

const DirectChatSubHeading = styled.div`
  font-size: 12px;
  opacity: 0.7;
  @media (max-width: 900px) {
    font-size: 9px;
    opacity: 0.9;
  }
`;

let btnStyle = { height: "30px", width: "40px", borderRadius: "200px" };

export default function ChatMessagesPanelHeader({ tab, setTab }) {
  const { isMobile } = useContext(Context);
  const { loggedInUserID } = useContext(Context);
  const { showPopup } = usePopupDialog();

  const { selectedChatGroupData, friendsLastSeenAt } = window.globalChatSpace;

  let { title, image, username, link } = getChatUiData(
    selectedChatGroupData.group,
    selectedChatGroupData.oppositeMember
  );

  let imageComp = null;

  if (selectedChatGroupData.group.type == "DIRECT_CHAT") {
    let oppositeMemberID = getOppositeMemberID({
      chatGroup: selectedChatGroupData.group,
      loggedInUserID,
    });
    let onlineStatus = getOnlineOfflineStatus({
      friendsLastSeenAt,
      oppositeMemberID: oppositeMemberID,
    });

    imageComp = (
      <ImageContainer>
        <Image src={getImageURL(image, true)} />
        {onlineStatus ? <OnlineBubble /> : null}
      </ImageContainer>
    );
  }

  let grpType = selectedChatGroupData.group.type;

  return (
    <TopPart>
      <BackButton>
        <FilledButton
          style={btnStyle}
          onClick={goTo(-1)}
          icon={<BsChevronLeft />}
        />
      </BackButton>

      {getImageAndTextComp()}

      {getOptionsButton()}
    </TopPart>
  );

  function getNumberOfMembersOnline() {
    if (grpType == "DIRECT_CHAT") return null;
    let count = 1; // it is me

    let { membersMembershipData } = selectedChatGroupData;

    for (let item of membersMembershipData) {
      if (item.userID == loggedInUserID) continue;
      let status = getOnlineOfflineStatus({
        friendsLastSeenAt,
        oppositeMemberID: item.userID,
      });

      if (status) count++;
    }

    if (count <= 1) return null;

    return <HeadingInfo>{count} Online</HeadingInfo>;
  }

  function openOptions() {
    showPopup({ title: "Options", component: <ChatOptionsSection /> });
  }

  function getOptionsButton() {
    if (grpType == "DIRECT_CHAT")
      return (
        <FilledButton
          style={btnStyle}
          onClick={openOptions}
          icon={<BsThreeDots />}
        ></FilledButton>
      );

    return getTabSwitcher();
  }

  function getTabSwitcher() {
    if (grpType == "DIRECT_CHAT") return null;
    if (!isMobile) return null;
    return (
      <DropDownInput
        style={btnStyle}
        hideLabel={true}
        disableSearch={true}
        value={tab}
        onChange={setTab}
        options={rightPanelOptions}
      />
    );
  }

  function getImageAndTextComp() {
    if (grpType == "DIRECT_CHAT")
      return (
        <DirectChatImageAndText onClick={goTo(link)}>
          {imageComp}
          <DirectChatText>
            <DirectChatTitle>
              {limitStringLength(title, isMobile ? 20 : 50)}
            </DirectChatTitle>
            <DirectChatSubHeading>
              {getSubHeading()}
              {getNumberOfMembersOnline()}
              {getTypingStatus()}
            </DirectChatSubHeading>
          </DirectChatText>
        </DirectChatImageAndText>
      );

    return (
      <TopProfile>
        <TextSection>
          <Name onClick={goTo(link)}>
            {limitStringLength(title, isMobile ? 20 : 50)}
          </Name>
          <SubHeading>
            {getSubHeading()}
            {getNumberOfMembersOnline()}
            {getTypingStatus()}
          </SubHeading>
        </TextSection>
      </TopProfile>
    );
  }

  function getSubHeading() {
    if (selectedChatGroupData.group.type == "CONTENT_CHAT") {
      let memberCount = selectedChatGroupData.membersMembershipData.length;

      return <HeadingInfo>{memberCount} Members</HeadingInfo>;
    }

    if (!username) return null;
    return (
      <SemanticButton
        semanticHref={getProfileLink(username)}
        onClick={goToProfile(username)}
      >
        <Username> @{username}</Username>
      </SemanticButton>
    );
  }

  function getTypingStatus() {
    return <HeadingInfo>{readTypingData()}</HeadingInfo>;
  }
}
