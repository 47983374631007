export default function addGroupToChatGroupPanel({
  messageDoc,
  membershipData,
  chatGroup,
  oppositeMember,
  itsAnUnreadMessage,
}) {
  let { chatGroups, setChatGroups, mutedChatGroups, setMutedChatGroups } =
    window.globalChatSpace;

  let chatGroupList = chatGroups;

  if (membershipData) {
    if (membershipData.isMuted) chatGroupList = mutedChatGroups;
  }

  let chatGroupID = chatGroup._id;

  let groupFound = false;

  let newGroups = [...chatGroupList];

  for (let item of newGroups) {
    if (item._id == chatGroupID) {
      groupFound = true;
      item.lastMessageSentAt = new Date().toISOString();
    }
  }

  if (!groupFound) {
    newGroups = [
      {
        ...chatGroup,
      },
      ...newGroups,
    ];

    if (oppositeMember) addSenderDataToOppositeMembers(oppositeMember);
  }

  // console.log("Group panel received an update");

  if (itsAnUnreadMessage) incrementUnreadMessages(chatGroupID, messageDoc);

  let newList = rankGroups(newGroups);

  if (!membershipData) return setChatGroups(newList);

  if (membershipData.isMuted) {
    return setMutedChatGroups(newList);
  } else {
    return setChatGroups(newList);
  }
}

function addSenderDataToOppositeMembers(item) {
  let { setOppositeMembers, oppositeMembers } = window.globalChatSpace;

  let newData = { ...oppositeMembers };

  newData[item._id] = item;

  setOppositeMembers(newData);
}

function incrementUnreadMessages(chatGroupID, messageDoc) {
  // console.log("Incrementing unread messages....");
  const {
    chatMembershipAndNotifications,
    setChatMembershipAndNotifications,
    loggedInUserID,
  } = window.globalChatSpace;

  let newMembershipAndNotifications = { ...chatMembershipAndNotifications };

  if (!newMembershipAndNotifications[chatGroupID])
    newMembershipAndNotifications[chatGroupID] = {
      unreadMessages: 0,
      unreadMentions: 0,
    };

  let existingDoc = newMembershipAndNotifications[chatGroupID];

  existingDoc.unreadMessages++;

  if (messageDoc.replyOf) {
    if (messageDoc.replyOf.authorUserID == loggedInUserID) {
      existingDoc.unreadMentions++;
    }
  }

  setChatMembershipAndNotifications(newMembershipAndNotifications);
}

function rankGroups(theArray) {
  return theArray.sort(function (a, b) {
    a = new Date(a.lastMessageSentAt).getTime();
    b = new Date(b.lastMessageSentAt).getTime();

    return b - a;
  });
}
