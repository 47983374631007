import { styled } from "styled-components";
import LoginWithGoogleBtn from "../../../pages/homePage/components/LoginWithGoogleBtn";

const Container = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export default function PopupLogin() {
  return (
    <Container>
      <LoginWithGoogleBtn />
    </Container>
  );
}
